$(document).on('turbolinks:load', function(){
  $("form").on('click', 'a.remove_fields', function(e) {
    e.preventDefault();
    $(this).closest(".input-group-append").prev('input[type=hidden]').val(1);
    $(this).closest('.input-group').hide();
  });

  $("form").on('click', ".add_fields", function(e) {
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    $(this).prev().find("input:visible").first().focus();
    e.preventDefault();
  });

})