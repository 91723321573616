function enableReveal($scope) {

  function updateDependentChildrenVisibility(parentElement){
    var name = $(parentElement).attr('name');
    $("[data-reveal*='" + name + "']").each(function(){
      updateChildVisibility(this);
    });
  }

  function updateChildVisibility(childElement){
    // find all elements that could show this child:
    var showElement = false;
    if ($(childElement).data('reveal') == undefined) {
      return;
    }
    var revealOnElements = $(childElement).data('reveal').split(",");
    if (revealOnElements.length > 1) {
      // We have multiple parents and need to loop through them
      for (i = 0; i < revealOnElements.length; i++){
        var parentElementName = revealOnElements[i];
        var revealValuesAry = eval($(childElement).data('reveal-value'));
        var revealValues = eval(revealValuesAry[i]);
        showElement = doesParentHaveValue(parentElementName, revealValues);
        if (showElement) {
          break;
        }
      }
    } else {
      // we have a single parent
      var revealValues = $(childElement).data('reveal-value');
      if (typeof(revealValues) == 'string' && revealValues.indexOf("[") > -1){
        revealValues = eval(revealValues);
      }
      showElement = doesParentHaveValue(revealOnElements[0], revealValues);
    }
    if (showElement) {
      revealElement(childElement);
    }else{
      hideElement(childElement);
    }
  }

  function doesParentHaveValue(parentElementName, needleValues) {
    // Find the first matching parent with this name so we can check its type
    // If we don't find a parent, return false
    if (typeof(needleValues) == 'undefined') {
      needleValues = [true];
    }
    var firstParent = $("[name='" + parentElementName + "']").map(function(){return $(this).attr('type') == 'hidden' ? null : this})[0]
    if (typeof(firstParent) == "undefined") {
      return false;
    }
    // ensure needleValues is always an array
    if (!Array.isArray(needleValues)) {
      needleValues = [needleValues];
    }
    var parentValues = [$(firstParent).val()];
    // If the parent is a checkbox or radio button, get an array of parentValues
    if ($(firstParent).attr('type') == 'checkbox' || $(firstParent).attr('type') == 'radio') {
      parentValues = $("[name='" + parentElementName + "']").map(function(){return $(this).is(":checked") ? $(this).val() : null}).get();
    }
    if (parentValues.length == 0) {
      return false;
    }
    var valueFound = false;
    // loop through the needle values and check if any of them exists in the parentValues array
    for (i = 0; i < needleValues.length; i++) {
      var needleValue = needleValues[i];
      if (needleValue == "*") {
        valueFound = true;
      } else {
        valueFound = $.inArray(needleValue.toString(), parentValues) == -1 ? false : true;
      }
      if (valueFound) {
        break;
      }
    }
    return valueFound;
  }

  function revealElement(element){
    // We use hidden inputs for the _buttons partial. We want to keep those inputs hidden always.
    if ((element.type != 'checkbox' && element.type != 'radio') || $(element).siblings().map(function(){return this.type == 'button' ? 1 : null}).length == 0) {
      $(element).show();
    }
    $(element).closest('.form-group').show();
    $(element).closest('.btn-toggle').show();
  }

  function hideElement(element){
    $(element).hide();
    element.checked = false;
    $(element).closest('.form-group').hide();
    $(element).closest('.btn-toggle').hide();
    updateDependentChildrenVisibility(element);
  } 
    
  
  function addOnChangeEvents($scope){
    var parentNameArray = $scope.find("[data-reveal]").map(function(){return $(this).data('reveal')});
    var eventAddedParents = [];
    for (i = 0; i < parentNameArray.length; i++) {
      var currentNames = parentNameArray[i];
      var currentNamesArray = currentNames.split(",");
      for (n = 0; n < currentNamesArray.length; n++) {
        var currentName = currentNamesArray[n];
        if ($.inArray(currentName.toString(), eventAddedParents) == -1) {
          var parentElement = $("[name='" + currentName + "']");
          parentElement.on('change', function(event){
            updateDependentChildrenVisibility(event.target);
          });
          eventAddedParents.push(currentName);
        } 
      }
    }
  }
  
  function setInitialState($scope){
    $scope.find("[data-reveal]").each(function(){
      updateChildVisibility(this);
    })
  }
  
  function initializePage($scope){
    addOnChangeEvents($scope);
    setInitialState($scope);
  }

  initializePage($scope);

}

$(document).on('turbolinks:load', function() {
  enableReveal($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableReveal($(event.target));
})
