$(document).on('turbolinks:load', function(){
  $("form.new_vote_submission").on('click', 'input[type="submit"]', function(e) {

    $("form .form-group").each(function(index) {
      if ($(this).find("input:checked").length == 0) {
        e.preventDefault();
        e.stopPropagation();
        alert("Please select a response for all votes before contining.");
        return false;
      }
    });
    $(this).submit();
  });



})