$(document).on("turbolinks:load", function(){
  $(".proxy-vote-button").on("click", function(event){
    event.preventDefault();
    let values = []
    $("form .form-group").first().find(":checked").each(function(i){
      values.push(this.value)
    })
    $("input:checkbox, input:radio").each(function() {
      if (values.includes(this.value)) {
        this.checked = true
      } else {
        this.checked = false
      }
    })
    $("form .form-group").each(function() {
      setDisabled(this)
    })
  });

  function setDisabled(formGroup) {
    if (formGroup.dataset.multiselect === undefined) {
      return;
    }
    let maxOptions = parseInt(formGroup.dataset.multiselect)
    let currentlySelected = formGroup.querySelectorAll("input:checked").length
    if (currentlySelected >= maxOptions) {
      formGroup.querySelectorAll("input:not(:checked):not([type='hidden'])").forEach(function(element) {
        element.closest(".btn-toggle").querySelector("button").disabled = true
      })
      formGroup.querySelectorAll("input:checked").forEach(function(element){
        element.closest(".btn-toggle").querySelector("button").disabled = false
      })
    } else {
      formGroup.querySelectorAll("button").forEach(element => element.disabled = false)
    }
  }

  window.setDisabled = setDisabled


  $(".form-group[data-multiselect] button").on("click", function() {
    setDisabled(this.closest("[data-multiselect]"))
  })

  document.querySelectorAll("input[type='checkbox']").forEach(function(element) {
    element.name += "[]"
    // element.value = `[${element.value}]`

    })

  $("input:hidden[value='0']").each(function(i) {
    this.remove();
  })

})